<template>
  <div class="content">
    <div class="top">
      <div class="left">
        <img src="../../assets/static/images/new.png" alt="" @click="goReceive(0)" />
        <img src="../../assets/static/images/photoshop.png" alt="" @click="goReceive(1)" />
        <img src="../../assets/static/images/feedback.png" alt="" @click="goFeedback" />
        <img src="../../assets/static/images/huanz.png" alt="" />
      </div>
      <div class="integral">
        <img src="../../assets/static/images/integral.png" alt="" @click="getIntegral" />
        <transition name="slide-fade">
          <div v-show="integral">您当前积分{{ integralCount }}分</div>
        </transition>
      </div>
    </div>
    <div class="center">
      <div class="right">
        <img src="../../assets/static/images/customer_service.png" alt="" />
        <div class="message-list" ref="messageList">
          <transition name="slide-fade">
            <div class="items" v-show="show">{{ rollMessageList[key] }}</div>
          </transition>
          <transition
            name="slide-fade"
            v-for="item in messageList2"
            :key="item.id"
          >
            <div
              class="items"
              v-show="item.show"
              :class="item.sender == 'me' ? 'sender' : ''"
            >
              <span v-html="item.message"></span>
            </div>
          </transition>
          <div class="items" v-show="!messageList2.length && show">
            <div class="item">下面是大家常问的问题哦</div>
            <div
              class="item"
              v-for="item in kbsList"
              :key="item.id"
              @click="
                message = '问题1';
                send_message();
              "
            >
              {{ item.questions }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="../../assets/static/images/keyboard.png" alt="" @click="inputShow = true" />
      <img src="../../assets/static/images/voice.png" alt="" />
      <img src="../../assets/static/images/video.png" alt="" />
    </div>
    <div class="message-box" v-show="inputShow">
      <input type="text" class="input" v-model="message" />
      <div class="button send" @click="send_message">发送</div>
      <div class="button" @click="inputShow = false">取消</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      integralCount: 0,
      integral: false,
      inputShow: false,
      kbsList: [],
      dialogVisible: false,
      message: "",
      socket: "",
      messageList: [],
      messageList2: [],
      chatRecordPage: 1,
      ws: {
        SocketTask: null,
        Timer: null, // 计时器
        ErrorMsg: [], // 发送失败的消息
        MaxRetryCount: 3, // 最大重连次数
        socketOpen: false,
      },
      csr: "", // 当前用户的客服代表ID
      sessionId: 0, // 会话ID
      errorTips: "连接中...",
      timer: {},
      timer2: {},
      show: true,
      rollMessageList: [
        "你来了，小亿也很想你，一日不见如隔三秋，你是不是也是这样的感觉呀？ ",
        "现在好：我是小亿  是您的专属客服",
        "不要客气，遇到任何有问题尽管来问我哦～",
        "辛苦了，您工作这么长时间，休息一下吧，申申手臂，向远处望一望，做做眼睛保健操，这样会很舒服的。",
        "别忘记喝水呀～",
      ],
      key: 0,
    };
  },
  watch: {
    messageList: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          var messageList = this.$refs.messageList;
          messageList.scrollTop = messageList.scrollHeight;
        });
      },
    },
  },
  mounted() {
    this.init();
    this.timer = setInterval(() => {
      if (this.messageList2.length) {
        if (this.messageList2[0].time + 60000 <= Date.now()) {
          this.messageList2[0].show = false;
          setTimeout(() => {
            this.messageList2.splice(0, 1);
          }, 200);
        }
      }
    }, 500);

    this.timer2 = setInterval(() => {
      this.show = !this.show;
      if (this.show) {
        if (this.key < this.rollMessageList.length - 1) {
          this.key++;
        } else {
          this.key = 0;
        }
      }
    }, 1000 * 60 * 3);
  },
  methods: {
    // 获取积分
    getIntegral() {
      this.$api.score.count().then((res) => {
        this.integralCount = res.data.count;
        this.integral = !this.integral;
      });
    },
    goReceive(img_type) {
      if (this.vuex_userinfo.group_id == 1) {
        this.$router.push({ path: "/project", query: { img_type } });
      } else {
        this.$router.push({ path: "/send_image", query: { img_type } });
      }
    },
    goFeedback() {
      if (this.vuex_userinfo.group_id == 1) {
        this.$router.push("feedback");
      } else {
        this.$router.push("feedback_list");
      }
    },
    init: function () {
      this.dialogVisible = true;
      let data = {
        token: this.vuex_userinfo.token,
        kefu_tourists_token: this.kefu_tourists_token,
        fixed_csr: "",
      };
      this.$api.kefu.initialize(data).then((res) => {
        let { kefu_tourists_token } = res.data.token_list;
        let { new_msg, config } = res.data;
        // 配置
        this.config = config;
        // 保存游客token
        if (kefu_tourists_token) {
          this.$ku.vuex("kefu_tourists_token", kefu_tourists_token);
        }

        // 新消息提示
        if (new_msg) {
          this.new_message_prompt();
        }

        // 连接ws
        this.connect_socket();
      });
      // 获取知识库列表
      this.$api.kefu.getkbs().then((res) => {
        this.kbsList = res.data;
      });
    },
    // 新消息提示
    new_message_prompt() {
      let audio = new Audio();
      audio.src = this.config.upload.cdnurl + this.config.ringing;
      audio.play();
    },
    // 连接ws
    connect_socket: function () {
      if (this.ws.SocketTask && this.ws.socketOpen) {
        return;
      }
      let token = this.vuex_userinfo.token
        ? "&token=" + this.vuex_userinfo.token
        : "";
      let kefu_tourists_token = this.kefu_tourists_token
        ? "&kefu_tourists_token=" + this.kefu_tourists_token
        : "";
      let path = (
        axios.defaults.wsURL +
        ":" +
        this.config.websocket_port +
        "/?modulename=index" +
        token +
        kefu_tourists_token
      ).replace(/\|/g, "~");
      // 实例化socket
      this.socket = new WebSocket(path);
      // 监听socket连接
      this.socket.onopen = this.open;
      // 监听socket错误信息
      this.socket.onerror = this.error;
      // 监听socket消息
      this.socket.onmessage = this.getMessage;
    },
    open: function () {
      this.ws.SocketTask = true;
      this.ws.socketOpen = true;
      // 重新发送所有出错的消息
      for (let i in this.ws.ErrorMsg) {
        this.ws_send(this.ws.ErrorMsg[i]);
      }
      this.ws.ErrorMsg = [];
      this.errorTips = "";

      if (this.ws.Timer != null) {
        clearInterval(this.ws.Timer);
      }

      this.ws.Timer = setInterval(this.ws_send, 28000); //定时发送心跳
    },
    error: function () {
      this.ws.socketOpen = false;
      this.errorTips = "WebSocket 发生错误!";
    },
    getMessage: function (res) {
      let msg = JSON.parse(res.data);
      let actions = this.domsg();
      let action = actions[msg.msgtype] || actions["default"];
      action.call(this, msg);
    },
    // 发送ws消息
    ws_send: function (message) {
      if (!message) {
        message = {
          c: "Message",
          a: "ping",
        };
      }

      if (this.ws.SocketTask && this.ws.socketOpen) {
        this.socket.send(JSON.stringify(message));
      } else {
        this.ws.ErrorMsg.push(message);
      }
    },

    send_message: function (e) {
      if (e?.ctrlKey && e.keyCode == 13) {
        this.message += "\n";
        return;
      }
      if (!this.message) {
        return;
      }
      // 检查 websocket 是否连接
      if (!this.ws.SocketTask || !this.ws.socketOpen) {
        this.$message.error("网络链接异常，请稍后重试~");
        return;
      }

      if (!this.sessionId) {
        this.$message.error("初始化未完成(会话获取失败)~");
        return;
      }

      let message_data = this.messageList[this.messageList.length - 1].data;
      var message_id = parseInt(message_data[message_data.length - 1].id) + 1;
      // var message_id = new Date().getTime() + this.sessionId + Math.floor(Math.random() * 10000);
      var load_message = {
        c: "Message",
        a: "sendMessage",
        data: {
          message: this.message,
          message_type: 0,
          session_id: this.sessionId,
          modulename: "index",
          message_id: message_id,
        },
      };

      this.ws_send(load_message);

      var data = {
        id: message_id,
        status: 2, // 标记待发送状态
        sender: "me",
        message: this.message,
        message_type: 0,
        show: true,
        time: Date.now(),
      };
      this.messageList2.push(data);

      var messageListIndex = this.messageList.length - 1;
      if (
        this.messageList[messageListIndex] &&
        this.messageList[messageListIndex].datetime == "刚刚"
      ) {
        this.messageList[messageListIndex].data = this.messageList[
          messageListIndex
        ].data.concat(this.format_message([data]));
      } else {
        this.messageList = this.messageList.concat({
          datetime: "刚刚",
          data: this.format_message([data]),
        });
      }

      this.message = "";
      this.inputShow = false;
    },
    // eslint-disable-next-line no-unused-vars
    domsg: function (msg) {
      return {
        // eslint-disable-next-line no-unused-vars
        default: (msg) => {},
        initialize: (msg) => {
          if (msg.data.new_msg) {
            this.new_message_prompt();
          }

          // 分配/获取客服->获取聊天记录
          var user_initialize = {
            c: "Message",
            a: "userInitialize",
            data: {
              fixed_csr: this.fixedCsr,
            },
          };
          this.ws_send(user_initialize);
        },
        user_initialize: (msg) => {
          // 用户客服分配结束
          if (msg.code == 1) {
            if (msg.data.session.user_tourists) {
              this.send_message = function () {
                this.$message({
                  message: "为保护您的隐私请,请登录后发送消息~",
                  type: "warning",
                });
              };
            }

            this.csr = msg.data.session.csr;
            this.sessionId = msg.data.session.id;
          } else if (msg.code == 302) {
            if (!this.csr) {
              // 打开留言板
              this.csr = "none";
            }
          }
        },
        clear: (msg) => {
          if (msg.msg) {
            // uni.showToast({
            //   title: msg.msg,
            //   icon: "none",
            // });
          }

          var clear = {
            c: "Message",
            a: "clear",
          };
          this.ws_send(clear);
        },
        new_message: (msg) => {
          this.new_message_prompt();

          if (msg.data.session_id == this.sessionId) {
            var messageListIndex = this.messageList.length - 1;
            msg.data.id = msg.data.record_id;
            if (this.messageList[messageListIndex].datetime == "刚刚") {
              this.messageList[messageListIndex].data = this.messageList[
                messageListIndex
              ].data.concat(this.format_message([msg.data]));
            } else {
              this.messageList = this.messageList.concat({
                datetime: "刚刚",
                data: this.format_message([msg.data]),
              });
            }

            var load_message = {
              c: "Message",
              a: "readMessage",
              data: {
                record_id: msg.data.record_id,
                session_id: this.sessionId,
              },
            };

            this.ws_send(load_message);

            msg.data.show = true;
            msg.data.time = Date.now();
            this.messageList2.push(msg.data);
          }
        },
        chat_record: (msg) => {
          let { chat_record, next_page, page } = msg.data;
          this.chatRecordPage = next_page;
          for (let i in chat_record) {
            chat_record[i].data = this.format_message(chat_record[i].data);
          }
          if (page == 1) {
            this.messageList = chat_record;
          } else {
            for (let i = chat_record.length - 1; i >= 0; i--) {
              chat_record[i].data.reverse();
              this.messageList.unshift(chat_record[i]);
            }
          }
        },
        send_message: (msg) => {
          if (!msg.data.message_id) {
            return;
          }

          var messageListIndex = this.messageList.length - 1;
          for (let i in this.messageList[messageListIndex].data) {
            if (
              this.messageList[messageListIndex].data[i].id ==
              msg.data.message_id
            ) {
              this.messageList[messageListIndex].data[i].status =
                msg.code == 1 ? 0 : 3;
              this.messageList[messageListIndex].data[i].id = msg.data.id;
            }
          }
        },
      };
    },
    close: function () {},
    format_message: function (message) {
      for (let i in message) {
        if (message[i].message_type == 4 || message[i].message_type == 5) {
          var message_arr = message[i].message.split("#");
          var message_obj = {};

          for (let y in message_arr) {
            let message_temp = message_arr[y].split("=");
            if (typeof message_temp[1] != "undefined") {
              message_obj[message_temp[0]] = message_temp[1];
            }
          }
          message[i].message = message_obj;
        }
      }
      return message;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active 用于 2.1.8 以下版本 */ {
  transform: translateX(10px);
  opacity: 0;
}

.content {
  height: 100vh;
  background-image: linear-gradient(#00296a, #013d9d 10%, #013d9d 25%, #00173a);
  padding: 32px 0 38px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  .top {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    height: 0;
    .left {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      z-index: 999;
      img {
        margin-bottom: 25px;
        width: 15vw;
      }
    }
    .integral {
      width: 30vw;
      color: #fff;
      font-size: 12px;
      img {
        width: 100%;
        z-index: 2;
      }
    }
  }
  .center {
    display: flex;
    padding: 0 15px;
    z-index: 1;
    .right {
      width: 100%;
      position: relative;
      img {
        width: 100%;
      }
      .message-list {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        overflow: auto;
        .items {
          background: rgba(255, 255, 255, 0.2);
          color: #fff;
          padding: 10px 15px;
          border-radius: 0 10px 10px 10px;
          font-size: 15px;
          text-align: left;
          margin-bottom: 20px;
          .item {
            margin-bottom: 10px;
          }
        }
        .sender {
          color: #efcb1f;
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 75px;
    z-index: 2;
    img {
      width: 15%;
      height: 100%;
    }
  }

  .message-box {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    background: #001c4a;
    box-shadow: 0px 7px 40px 0px rgba(94, 207, 250, 0.59);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    .input {
      width: 70%;
      background: none;
      border-radius: 5px;
      height: 50px;
      font-size: 15px;
      padding: 0 10px;
      color: #fff;
      border: 1px solid;
    }
    .button {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #b8b8b8;
    }
    .send {
      color: #fff;
    }
  }
}
</style>
